const middleware = {}

middleware['authorized'] = require('../src/middleware/authorized.ts')
middleware['authorized'] = middleware['authorized'].default || middleware['authorized']

middleware['checkout'] = require('../src/middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['orderFormation'] = require('../src/middleware/orderFormation.js')
middleware['orderFormation'] = middleware['orderFormation'].default || middleware['orderFormation']

middleware['redirectOnFirstPage'] = require('../src/middleware/redirectOnFirstPage.ts')
middleware['redirectOnFirstPage'] = middleware['redirectOnFirstPage'].default || middleware['redirectOnFirstPage']

middleware['setAxiosHeaders'] = require('../src/middleware/setAxiosHeaders.js')
middleware['setAxiosHeaders'] = middleware['setAxiosHeaders'].default || middleware['setAxiosHeaders']

middleware['viewId'] = require('../src/middleware/viewId.js')
middleware['viewId'] = middleware['viewId'].default || middleware['viewId']

export default middleware
