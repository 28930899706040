export default function (to, from, savedPosition) {
  if (to.hash) {
    const header =
      window.innerWidth <= 1025
        ? document.querySelector('.rph-header-mobile')
        : document.querySelector('.rph-header')
    return {
      selector: to.hash,
      offset: { x: 0, y: header != null ? header.offsetHeight : 0 },
    }
  }
  return { x: 0, y: 0 }
}
